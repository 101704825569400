import { ProtocolAction } from '@yldr/contract-helpers';
import { Trans } from '@lingui/macro';
import { BoxProps } from '@mui/material';
import { useTransactionHandler } from 'src/helpers/useTransactionHandler';
import { ComputedReserveData } from 'src/hooks/app-data-provider/useAppDataProvider';
import { useRootStore } from 'src/store/root';

import { TxActionsWrapper } from '../TxActionsWrapper';

export interface RepayActionProps extends BoxProps {
  amountToRepay: string;
  poolReserve: ComputedReserveData;
  isWrongNetwork: boolean;
  customGasPrice?: string;
  poolAddress: string;
  symbol: string;
  repayWithYTokens: boolean;
  blocked?: boolean;
}

export const RepayActions = ({
  amountToRepay,
  poolReserve,
  poolAddress,
  isWrongNetwork,
  sx,
  symbol,
  repayWithYTokens,
  blocked,
  ...props
}: RepayActionProps) => {
  const { repay, repayWithPermit, tryPermit } = useRootStore();

  const usingPermit = tryPermit({
    reserveAddress: poolAddress,
    isWrappedBaseAsset: poolReserve.isWrappedBaseAsset,
  });
  const { approval, action, requiresApproval, loadingTxns, approvalTxState, mainTxState } =
    useTransactionHandler({
      tryPermit: usingPermit,
      permitAction: ProtocolAction.repayWithPermit,
      protocolAction: ProtocolAction.repay,
      eventTxInfo: {
        amount: amountToRepay,
        assetName: poolReserve.name,
        asset: poolReserve.underlyingAsset,
      },
      handleGetTxns: async () => {
        return repay({
          amountToRepay,
          poolAddress,
          repayWithYTokens,
          poolReserve,
          isWrongNetwork,
          symbol,
        });
      },
      handleGetPermitTxns: async (signatures, deadline) => {
        return repayWithPermit({
          amountToRepay,
          poolReserve,
          isWrongNetwork,
          poolAddress,
          symbol,
          repayWithYTokens,
          signature: signatures[0],
          deadline,
        });
      },
      skip: !amountToRepay || parseFloat(amountToRepay) === 0 || blocked,
      deps: [amountToRepay, poolAddress, repayWithYTokens],
    });

  return (
    <TxActionsWrapper
      blocked={blocked}
      preparingTransactions={loadingTxns}
      symbol={poolReserve.symbol}
      mainTxState={mainTxState}
      approvalTxState={approvalTxState}
      requiresAmount
      amount={amountToRepay}
      requiresApproval={requiresApproval}
      isWrongNetwork={isWrongNetwork}
      sx={sx}
      {...props}
      handleAction={() => {
        window.gtag('event', 'lending_repay_modal_confirm', {
          asset: symbol,
        });
        return action();
      }}
      handleApproval={() => approval([{ amount: amountToRepay, underlyingAsset: poolAddress }])}
      actionText={<Trans>Repay {symbol}</Trans>}
      actionInProgressText={<Trans>Repaying {symbol}</Trans>}
      tryPermit={usingPermit}
    />
  );
};
